export const laboratoryDataMockup = {
  1584820500000: [
    {
      province: 'Auenland',
      country: 'Eriador',
      latlng: [46.8131873, 8.22421],
      confirmed: 10000,
      recovered: 1,
      deaths: 5000,
      active: 4999,
    },
    {
      province: 'Hyarrostar',
      country: 'Númenor',
      latlng: [51.1642292, 10.4541194],
      confirmed: 100000,
      recovered: 1000,
      deaths: 60000,
      active: 39000,
    },
    {
      province: 'Gorgoroth',
      country: 'Mordor',
      latlng: [47.6964719, 13.3457347],
      confirmed: 20,
      recovered: 0,
      deaths: 0,
      active: 20,
    }
  ],
  1584820586400: [
    {
      province: 'Auenland',
      country: 'Eriador',
      latlng: [46.8131873, 8.22421],
      confirmed: 20000,
      recovered: 10,
      deaths: 15000,
      active: 9990,
    },
    {
      province: 'Hyarrostar',
      country: 'Númenor',
      latlng: [51.1642292, 10.4541194],
      confirmed: 100000,
      recovered: 40000,
      deaths: 60000,
      active: 0,
    },
    {
      province: 'Gorgoroth',
      country: 'Mordor',
      latlng: [47.6964719, 13.3457347],
      confirmed: 50,
      recovered: 10,
      deaths: 20,
      active: 20,
    }
  ],
  1584820672800: [
    {
      province: 'Auenland',
      country: 'Eriador',
      latlng: [46.8131873, 8.22421],
      confirmed: 100000,
      recovered: 20,
      deaths: 50000,
      active: 49980,
    },
    {
      province: 'Hyarrostar',
      country: 'Númenor',
      latlng: [51.1642292, 10.4541194],
      confirmed: 160000,
      recovered: 80000,
      deaths: 65000,
      active: 15000,
    },
    {
      province: 'Gorgoroth',
      country: 'Mordor',
      latlng: [47.6964719, 13.3457347],
      confirmed: 200,
      recovered: 40,
      deaths: 80,
      active: 80,
    }
  ]
}

export const surveyDataMockup = [
  {
    "id": "5e813d16588ed15b90c9098f",
    "infectionPropability": "0.0401",
    "latlng": [
      "47.4901",
      "7.5813"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c0a42fccc2458de3",
    "infectionPropability": "0.8445",
    "latlng": [
      "47.2853",
      "9.8721"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16cfb6d3648e47078c",
    "infectionPropability": "0.9423",
    "latlng": [
      "45.3637",
      "7.0287"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16bf43c25cc21c1f85",
    "infectionPropability": "0.456",
    "latlng": [
      "44.3054",
      "7.9841"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d165b3b31c68894cd96",
    "infectionPropability": "0.3728",
    "latlng": [
      "44.6069",
      "7.8438"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c54a7cc3c30be488",
    "infectionPropability": "0.9292",
    "latlng": [
      "46.8478",
      "6.2512"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16d3d50ebacbed2dfc",
    "infectionPropability": "0.4621",
    "latlng": [
      "47.5729",
      "7.6801"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168961fa3a0b1795fd",
    "infectionPropability": "0.3776",
    "latlng": [
      "45.4116",
      "8.5106"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162b0d075f613f2549",
    "infectionPropability": "0.9922",
    "latlng": [
      "44.0719",
      "7.5792"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16512722b296b6cef9",
    "infectionPropability": "0.0458",
    "latlng": [
      "44.3933",
      "6.3467"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1619d736ec85baabad",
    "infectionPropability": "0.8495",
    "latlng": [
      "44.6817",
      "6.8214"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1648919ee9eb62f369",
    "infectionPropability": "0.9434",
    "latlng": [
      "45.767",
      "8.4278"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1656be5ea204e8eac9",
    "infectionPropability": "0.2526",
    "latlng": [
      "45.2926",
      "6.2086"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f5c295cf375850b8",
    "infectionPropability": "0.9614",
    "latlng": [
      "46.589",
      "6.5793"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f84ce84c232077c0",
    "infectionPropability": "0.8203",
    "latlng": [
      "44.2434",
      "7.1189"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168a70a64b5ca7c24d",
    "infectionPropability": "0.7293",
    "latlng": [
      "44.217",
      "7.5052"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d169162555ebd7302c8",
    "infectionPropability": "0.7648",
    "latlng": [
      "44.81",
      "9.7648"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d163650234c01b200ec",
    "infectionPropability": "0.9855",
    "latlng": [
      "45.3264",
      "6.1604"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d160bca4029f4aeacf4",
    "infectionPropability": "0.0826",
    "latlng": [
      "45.7308",
      "7.7823"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f2ea99b5ec893414",
    "infectionPropability": "0.2097",
    "latlng": [
      "45.2897",
      "8.8763"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1691bf07a48414e919",
    "infectionPropability": "0.1682",
    "latlng": [
      "47.3797",
      "6.5406"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162f95620e076fd31c",
    "infectionPropability": "0.0766",
    "latlng": [
      "44.5476",
      "9.346"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d166bcc718917e0cba2",
    "infectionPropability": "0.6588",
    "latlng": [
      "47.0101",
      "7.5345"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16601bf8d212a721bb",
    "infectionPropability": "0.6934",
    "latlng": [
      "46.7073",
      "6.2466"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d161bddcc95cba6d4ae",
    "infectionPropability": "0.9162",
    "latlng": [
      "46.2574",
      "6.9983"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16300df1360a73a403",
    "infectionPropability": "0.0371",
    "latlng": [
      "47.4227",
      "9.4073"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16ebeeded8f216113d",
    "infectionPropability": "0.2315",
    "latlng": [
      "46.5053",
      "8.3678"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c467112a96118c16",
    "infectionPropability": "0.0091",
    "latlng": [
      "46.4748",
      "7.667"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1616bec0d7e0f2eaf6",
    "infectionPropability": "0.535",
    "latlng": [
      "45.9141",
      "7.9555"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d165858ccbc27a67341",
    "infectionPropability": "0.1356",
    "latlng": [
      "45.1493",
      "6.7968"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1688fae5a49d3d7f4e",
    "infectionPropability": "0.3913",
    "latlng": [
      "46.1247",
      "7.599"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16304b79ca296c2627",
    "infectionPropability": "0.6754",
    "latlng": [
      "45.7002",
      "8.6701"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16ae5fe1cfbe72e8ba",
    "infectionPropability": "0.5566",
    "latlng": [
      "47.1832",
      "6.1474"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162e476e228e9aa960",
    "infectionPropability": "0.3225",
    "latlng": [
      "46.1516",
      "9.9004"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1694083e1080e8ebda",
    "infectionPropability": "0.3985",
    "latlng": [
      "45.5829",
      "9.4435"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168d97c8de8fb9a8ca",
    "infectionPropability": "0.7374",
    "latlng": [
      "46.5656",
      "8.8668"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d169cb26f09082fedd2",
    "infectionPropability": "0.9125",
    "latlng": [
      "47.9909",
      "8.1083"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d167f93530d17a2f9c9",
    "infectionPropability": "0.166",
    "latlng": [
      "46.1013",
      "7.4352"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d161ebca7283b46156c",
    "infectionPropability": "0.7104",
    "latlng": [
      "45.3049",
      "9.5713"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d169b4360045d426f9a",
    "infectionPropability": "0.5953",
    "latlng": [
      "46.3112",
      "9.5731"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16e2a980267279f5c3",
    "infectionPropability": "0.925",
    "latlng": [
      "44.6937",
      "8.5396"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c3c4e0605ba62cea",
    "infectionPropability": "0.0035",
    "latlng": [
      "44.4639",
      "7.9442"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162a5b67254effbef0",
    "infectionPropability": "0.9377",
    "latlng": [
      "45.1596",
      "8.6642"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d167e72d86e01ce6eb9",
    "infectionPropability": "0.3368",
    "latlng": [
      "47.603",
      "7.7403"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c026b9d0fbc71393",
    "infectionPropability": "0.8896",
    "latlng": [
      "44.5952",
      "9.4995"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16a295f69c3e3760fa",
    "infectionPropability": "0.7733",
    "latlng": [
      "45.6417",
      "7.9648"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16bab1e2b42441da2c",
    "infectionPropability": "0.5312",
    "latlng": [
      "45.244",
      "9.9635"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16cb11dee9bd98bd4f",
    "infectionPropability": "0.3828",
    "latlng": [
      "45.9093",
      "9.5607"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c88735946b660f65",
    "infectionPropability": "0.4637",
    "latlng": [
      "45.2147",
      "8.1741"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16de497f51e3a60ee8",
    "infectionPropability": "0.8825",
    "latlng": [
      "46.805",
      "6.4235"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1698ca028beccad1ee",
    "infectionPropability": "0.554",
    "latlng": [
      "45.1445",
      "7.7119"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1632d8fca0a20558fa",
    "infectionPropability": "0.4213",
    "latlng": [
      "44.8174",
      "9.6129"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16516e491365fe5685",
    "infectionPropability": "0.5514",
    "latlng": [
      "44.3221",
      "9.1454"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d165d1bdec1c940dc02",
    "infectionPropability": "0.6025",
    "latlng": [
      "46.2982",
      "9.1293"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c635ee2d3494e2de",
    "infectionPropability": "0.1373",
    "latlng": [
      "47.8136",
      "6.9054"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1602e8e71e1d0ad0ab",
    "infectionPropability": "0.6025",
    "latlng": [
      "47.561",
      "9.053"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16e8540b2742d90801",
    "infectionPropability": "0.7248",
    "latlng": [
      "46.4208",
      "6.4264"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d163d7844a68fa9e73b",
    "infectionPropability": "0.2245",
    "latlng": [
      "44.9032",
      "7.9576"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16422174701fe71fb8",
    "infectionPropability": "0.8713",
    "latlng": [
      "47.3489",
      "8.4667"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f185ab85cb0490d1",
    "infectionPropability": "0.7281",
    "latlng": [
      "44.0929",
      "9.4595"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16a7cb7f4a97f506f5",
    "infectionPropability": "0.737",
    "latlng": [
      "47.2827",
      "7.2187"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16b7f0865ecd1ed5af",
    "infectionPropability": "0.0016",
    "latlng": [
      "46.1668",
      "7.5941"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1671661188617dd883",
    "infectionPropability": "0.8614",
    "latlng": [
      "47.5822",
      "8.2162"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16d66f8b3161809d03",
    "infectionPropability": "0.0475",
    "latlng": [
      "46.8916",
      "8.6507"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d161b15288eccd3cce3",
    "infectionPropability": "0.5802",
    "latlng": [
      "47.7354",
      "9.4381"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1610e73b0bedbe2482",
    "infectionPropability": "0.1127",
    "latlng": [
      "47.4142",
      "9.8337"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16d9c1edec8fc1cdad",
    "infectionPropability": "0.0855",
    "latlng": [
      "44.7475",
      "6.6174"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162f133addb84616b1",
    "infectionPropability": "0.3777",
    "latlng": [
      "45.0959",
      "6.2783"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f53914073dd8c771",
    "infectionPropability": "0.5922",
    "latlng": [
      "47.9193",
      "6.049"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1639cca1f53952043a",
    "infectionPropability": "0.2316",
    "latlng": [
      "46.8021",
      "7.4013"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1646dccc42bbedb70e",
    "infectionPropability": "0.1398",
    "latlng": [
      "44.0257",
      "8.2493"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16f536959b3db01bb6",
    "infectionPropability": "0.1667",
    "latlng": [
      "47.4348",
      "6.5124"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d167617abdc0a1d552d",
    "infectionPropability": "0.472",
    "latlng": [
      "44.6122",
      "8.9984"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16de6febdd183d90e8",
    "infectionPropability": "0.0545",
    "latlng": [
      "47.6495",
      "8.6759"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d169c3b3782d90d20bf",
    "infectionPropability": "0.8032",
    "latlng": [
      "46.5576",
      "7.6598"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16a4d4c913cdb3a86e",
    "infectionPropability": "0.7526",
    "latlng": [
      "47.5182",
      "9.965"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168dd86fcbbcb8f595",
    "infectionPropability": "0.3715",
    "latlng": [
      "47.165",
      "6.9434"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1655a22b9fd2be9fca",
    "infectionPropability": "0.0816",
    "latlng": [
      "45.1688",
      "8.8549"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d166b33f1f2120a229a",
    "infectionPropability": "0.8109",
    "latlng": [
      "47.4878",
      "8.8682"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16cad0fa986cef1e38",
    "infectionPropability": "0.2229",
    "latlng": [
      "47.0463",
      "6.1804"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1614e30f286d26f3f5",
    "infectionPropability": "0.0461",
    "latlng": [
      "47.0449",
      "7.0147"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d169545618282e8869f",
    "infectionPropability": "0.6779",
    "latlng": [
      "47.2074",
      "7.0614"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16a06dbee2d67569a9",
    "infectionPropability": "0.2278",
    "latlng": [
      "47.2813",
      "9.3681"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16d995a6b422913df5",
    "infectionPropability": "0.5711",
    "latlng": [
      "44.1387",
      "8.6383"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1677640dac5e710d97",
    "infectionPropability": "0.7347",
    "latlng": [
      "44.0079",
      "6.8328"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d160f0ef70fa97b79de",
    "infectionPropability": "0.5281",
    "latlng": [
      "46.2816",
      "6.8083"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168fd99bb1b3b3ab64",
    "infectionPropability": "0.8763",
    "latlng": [
      "47.1408",
      "9.8868"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16e423ada784ae2ae6",
    "infectionPropability": "0.8406",
    "latlng": [
      "44.5257",
      "9.5815"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16dac6b75827c98734",
    "infectionPropability": "0.0132",
    "latlng": [
      "46.0531",
      "9.0852"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c6548aab9d0806b2",
    "infectionPropability": "0.1003",
    "latlng": [
      "47.8711",
      "9.5681"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d166e208b132d7162bc",
    "infectionPropability": "0.4962",
    "latlng": [
      "47.5943",
      "9.3756"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d162b2f091c30ea1117",
    "infectionPropability": "0.074",
    "latlng": [
      "46.5437",
      "9.2565"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16640b51bbb0d39399",
    "infectionPropability": "0.838",
    "latlng": [
      "47.1051",
      "6.9838"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16bd42c9aa4d0554c1",
    "infectionPropability": "0.4876",
    "latlng": [
      "47.1673",
      "6.4521"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16b371dcc12bd93354",
    "infectionPropability": "0.7804",
    "latlng": [
      "44.4957",
      "6.2912"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d168385cd38dfc1d824",
    "infectionPropability": "0.2819",
    "latlng": [
      "45.3729",
      "9.7885"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c3f56bb07e898ca6",
    "infectionPropability": "0.2997",
    "latlng": [
      "45.1413",
      "7.7613"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16e258f2a77d941eee",
    "infectionPropability": "0.9699",
    "latlng": [
      "46.8786",
      "9.2822"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d1698c23596aa24f5cd",
    "infectionPropability": "0.4094",
    "latlng": [
      "45.0458",
      "9.7717"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813d16c25caa2cc83ed570",
    "infectionPropability": "0.3573",
    "latlng": [
      "45.1673",
      "9.2818"
    ],
    "country": "Eriador"
  },
  {
    "id": "5e813fd92af6a3c894964065",
    "infectionPropability": "0.829",
    "latlng": [
      "52.1826",
      "9.726"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd928edc8a48d36724f",
    "infectionPropability": "0.3946",
    "latlng": [
      "52.8111",
      "9.6219"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91fe4822a7f0bd4bf",
    "infectionPropability": "0.4746",
    "latlng": [
      "52.6034",
      "9.7512"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9ce32831544d6b200",
    "infectionPropability": "0.3194",
    "latlng": [
      "49.249",
      "10.1787"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd97c823d129d4d4fe1",
    "infectionPropability": "0.315",
    "latlng": [
      "50.3105",
      "10.6021"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a77e7c2081720562",
    "infectionPropability": "0.332",
    "latlng": [
      "52.4787",
      "9.8046"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9c2b95c55cf78a57a",
    "infectionPropability": "0.8372",
    "latlng": [
      "52.9735",
      "9.9828"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9caa502b24d2e7170",
    "infectionPropability": "0.812",
    "latlng": [
      "52.4679",
      "10.9212"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd98ed04085790262d1",
    "infectionPropability": "0.6646",
    "latlng": [
      "50.6468",
      "9.6906"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9be5756b6a87ba97f",
    "infectionPropability": "0.1258",
    "latlng": [
      "50.3694",
      "10.5699"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9d39b0ada9b51dd3e",
    "infectionPropability": "0.6984",
    "latlng": [
      "51.3118",
      "9.4444"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9fdf29fbb3ed7a97d",
    "infectionPropability": "0.2884",
    "latlng": [
      "52.4865",
      "9.2274"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a939ef5db10c3fa3",
    "infectionPropability": "0.1866",
    "latlng": [
      "49.6802",
      "10.8943"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd95ff47228fd450a4b",
    "infectionPropability": "0.2723",
    "latlng": [
      "52.1252",
      "9.04"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9715e8b5b79da2b5c",
    "infectionPropability": "0.8845",
    "latlng": [
      "50.3496",
      "9.3706"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9b15696b125a52813",
    "infectionPropability": "0.8662",
    "latlng": [
      "50.6295",
      "9.3602"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a390362dfd57aeff",
    "infectionPropability": "0.5213",
    "latlng": [
      "52.8959",
      "10.2419"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9bf92cffbc0edbfc4",
    "infectionPropability": "0.9369",
    "latlng": [
      "51.1541",
      "10.2835"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd98b1097daaa3a5fa5",
    "infectionPropability": "0.5103",
    "latlng": [
      "52.5047",
      "10.2788"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9d6c3d87cae7e254f",
    "infectionPropability": "0.706",
    "latlng": [
      "51.6172",
      "9.6858"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91240cd999a690b32",
    "infectionPropability": "0.384",
    "latlng": [
      "49.5594",
      "9.0881"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd92599b3e6cb6f6911",
    "infectionPropability": "0.4706",
    "latlng": [
      "51.147",
      "10.2383"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9732cc4720c6f2e2f",
    "infectionPropability": "0.4724",
    "latlng": [
      "50.8634",
      "9.7004"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd923c2dd48e5b2e757",
    "infectionPropability": "0.6512",
    "latlng": [
      "49.6136",
      "10.1746"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91bffc510e2b091f4",
    "infectionPropability": "0.84",
    "latlng": [
      "50.7403",
      "9.5323"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd92b1b3ebc64c6eef3",
    "infectionPropability": "0.5748",
    "latlng": [
      "49.6027",
      "9.4323"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9abe5646f09988a1f",
    "infectionPropability": "0.7521",
    "latlng": [
      "50.1988",
      "10.757"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd96edc9519b386ca19",
    "infectionPropability": "0.1995",
    "latlng": [
      "51.8687",
      "9.7403"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd991f9a884b7154fbd",
    "infectionPropability": "0.2669",
    "latlng": [
      "50.5372",
      "9.7524"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd96a371875364cc5a2",
    "infectionPropability": "0.0714",
    "latlng": [
      "51.0513",
      "10.3867"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd929aec8243d0c41c9",
    "infectionPropability": "0.5044",
    "latlng": [
      "50.3812",
      "9.4637"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9bbd25c6dddfe0d46",
    "infectionPropability": "0.9936",
    "latlng": [
      "51.7715",
      "9.4013"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd991fbc4e96b9854e2",
    "infectionPropability": "0.9187",
    "latlng": [
      "51.3023",
      "9.7286"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd99fcae8e18c68d4b6",
    "infectionPropability": "0.4878",
    "latlng": [
      "50.3699",
      "10.6998"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9e2253fcd16ebdd4d",
    "infectionPropability": "0.4567",
    "latlng": [
      "51.98",
      "10.5217"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91f8f73ae017587c8",
    "infectionPropability": "0.2135",
    "latlng": [
      "51.0917",
      "10.4685"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd92579bea431e96470",
    "infectionPropability": "0.3837",
    "latlng": [
      "49.4232",
      "9.8845"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9e2d4a8a973f89699",
    "infectionPropability": "0.5244",
    "latlng": [
      "52.6029",
      "10.0286"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9f54083a9704d4a35",
    "infectionPropability": "0.8371",
    "latlng": [
      "51.6477",
      "9.0967"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd92f2deb7dbe5fe88b",
    "infectionPropability": "0.7038",
    "latlng": [
      "52.9051",
      "10.0176"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd95dd364fd7f1f873f",
    "infectionPropability": "0.4966",
    "latlng": [
      "51.0464",
      "9.7731"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd979909aa80c524d0e",
    "infectionPropability": "0.7138",
    "latlng": [
      "52.347",
      "10.2701"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9768070314022da19",
    "infectionPropability": "0.7274",
    "latlng": [
      "50.2828",
      "9.7322"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd90bf5734446f18c30",
    "infectionPropability": "0.8867",
    "latlng": [
      "49.255",
      "10.6623"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9dfbd62434dacb083",
    "infectionPropability": "0.64",
    "latlng": [
      "51.9053",
      "10.1039"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9caf85bd438d035bd",
    "infectionPropability": "0.2517",
    "latlng": [
      "51.1126",
      "9.4093"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd990bf6b233ab430fd",
    "infectionPropability": "0.7276",
    "latlng": [
      "50.9335",
      "10.3906"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd96fc849eefa2d2e52",
    "infectionPropability": "0.0014",
    "latlng": [
      "50.898",
      "10.5242"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd970d967c6b3994d06",
    "infectionPropability": "0.6535",
    "latlng": [
      "52.8963",
      "10.1647"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a6a6a055d649ffac",
    "infectionPropability": "0.0756",
    "latlng": [
      "49.832",
      "10.0352"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a404160a46540de3",
    "infectionPropability": "0.8296",
    "latlng": [
      "51.2507",
      "9.1833"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9fbad81a38cfd0df9",
    "infectionPropability": "0.895",
    "latlng": [
      "49.3785",
      "9.7644"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd947e4912b774c1f27",
    "infectionPropability": "0.1095",
    "latlng": [
      "51.2509",
      "10.038"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd92a08da1116f9fcac",
    "infectionPropability": "0.3697",
    "latlng": [
      "49.4691",
      "9.2928"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9ffb21b0b620c2761",
    "infectionPropability": "0.1227",
    "latlng": [
      "50.9355",
      "9.2802"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9f33685744ecfd127",
    "infectionPropability": "0.7106",
    "latlng": [
      "52.6618",
      "10.152"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd901599d8865c9e14a",
    "infectionPropability": "0.6865",
    "latlng": [
      "51.4507",
      "9.4741"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9b95ebb3f5baceba8",
    "infectionPropability": "0.2823",
    "latlng": [
      "50.3607",
      "10.5619"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd93feac2e3de8c5648",
    "infectionPropability": "0.9771",
    "latlng": [
      "51.1251",
      "10.6722"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd935af3e451c35ef74",
    "infectionPropability": "0.5433",
    "latlng": [
      "50.2733",
      "9.576"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd97385856f80d868b7",
    "infectionPropability": "0.2951",
    "latlng": [
      "52.0397",
      "10.9156"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9ca13f2dbc85b99e1",
    "infectionPropability": "0.968",
    "latlng": [
      "50.1063",
      "9.8462"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9c5b8f6bfa55aeb50",
    "infectionPropability": "0.4862",
    "latlng": [
      "49.8553",
      "9.3894"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd98b95bca9ff751195",
    "infectionPropability": "0.6636",
    "latlng": [
      "49.9289",
      "9.1298"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd997dc10bcfde5c06d",
    "infectionPropability": "0.0585",
    "latlng": [
      "50.08",
      "9.5685"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9f42a048bf6b7c8b4",
    "infectionPropability": "0.1035",
    "latlng": [
      "50.6809",
      "10.2627"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9506529a49b3e923b",
    "infectionPropability": "0.419",
    "latlng": [
      "50.2877",
      "10.8129"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd992cb92e1366da520",
    "infectionPropability": "0.4657",
    "latlng": [
      "51.7637",
      "10.74"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd903e54af1c8d08093",
    "infectionPropability": "0.6747",
    "latlng": [
      "52.6863",
      "9.0834"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a3a0dc6364932329",
    "infectionPropability": "0.7469",
    "latlng": [
      "49.3569",
      "9.6892"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91da2a591786f969f",
    "infectionPropability": "0.1708",
    "latlng": [
      "49.2442",
      "10.3134"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9571bedbc657b3fbd",
    "infectionPropability": "0.6994",
    "latlng": [
      "51.3263",
      "9.775"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd995df0aecf9b3b3a8",
    "infectionPropability": "0.6893",
    "latlng": [
      "51.1342",
      "9.1597"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9113b32dad95db6ca",
    "infectionPropability": "0.2452",
    "latlng": [
      "51.5903",
      "9.0141"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd949b5b70687604f2a",
    "infectionPropability": "0.4168",
    "latlng": [
      "50.1563",
      "10.6615"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9366dae51e717bb1d",
    "infectionPropability": "0.892",
    "latlng": [
      "50.1422",
      "10.7092"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd98409354168943ffb",
    "infectionPropability": "0.9449",
    "latlng": [
      "51.4165",
      "9.7447"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd95c55419ddb5eba57",
    "infectionPropability": "0.5345",
    "latlng": [
      "50.3851",
      "9.1002"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9cd4411a65363ab19",
    "infectionPropability": "0.7434",
    "latlng": [
      "51.7739",
      "10.2824"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd94a97a73b770b6802",
    "infectionPropability": "0.6438",
    "latlng": [
      "50.2152",
      "10.1981"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd94f2c571dcbd79117",
    "infectionPropability": "0.6006",
    "latlng": [
      "52.1796",
      "9.3682"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9ed94f2308a7838c1",
    "infectionPropability": "0.6292",
    "latlng": [
      "49.3237",
      "10.135"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd91817e190667e78ff",
    "infectionPropability": "0.9218",
    "latlng": [
      "50.6271",
      "9.273"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9e56899d02a7c445b",
    "infectionPropability": "0.7591",
    "latlng": [
      "51.9028",
      "10.8398"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd97eec3da16398598a",
    "infectionPropability": "0.2466",
    "latlng": [
      "49.4711",
      "9.1299"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9dbbc91d54fa7f6ac",
    "infectionPropability": "0.2984",
    "latlng": [
      "50.9959",
      "9.5597"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd96d0e1f3ffecc97c8",
    "infectionPropability": "0.0683",
    "latlng": [
      "49.1675",
      "9.7982"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a9b52a7b8dfe9643",
    "infectionPropability": "0.1852",
    "latlng": [
      "49.2251",
      "9.7729"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd95ed33c2f0688097c",
    "infectionPropability": "0.0693",
    "latlng": [
      "50.1954",
      "10.6552"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9a1269c02bdf64547",
    "infectionPropability": "0.7225",
    "latlng": [
      "52.7741",
      "9.4504"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9c8773e8632fb177e",
    "infectionPropability": "0.9251",
    "latlng": [
      "49.3707",
      "10.0948"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9f23a07f5c704421e",
    "infectionPropability": "0.2122",
    "latlng": [
      "49.8812",
      "10.8206"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd964aa3cf4fe2e6a62",
    "infectionPropability": "0.2845",
    "latlng": [
      "50.9562",
      "10.2956"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9d9dbb5f1834b3a0c",
    "infectionPropability": "0.6781",
    "latlng": [
      "49.0939",
      "9.8768"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9cc3bf5627c7edfe5",
    "infectionPropability": "0.0256",
    "latlng": [
      "49.4335",
      "9.1372"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd961331747877f49ba",
    "infectionPropability": "0.4728",
    "latlng": [
      "52.5513",
      "9.8977"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd97e9f05247e50a9c0",
    "infectionPropability": "0.0766",
    "latlng": [
      "50.3724",
      "10.1626"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd983e8e67e08b1636f",
    "infectionPropability": "0.8403",
    "latlng": [
      "49.8745",
      "10.9941"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd99f68d6f941bb3fc6",
    "infectionPropability": "0.4862",
    "latlng": [
      "49.3082",
      "9.177"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e813fd9bc41ea4b95a4b771",
    "infectionPropability": "0.2954",
    "latlng": [
      "52.3954",
      "9.493"
    ],
    "country": "Númenor"
  },
  {
    "id": "5e814082f191c511c13789ee",
    "infectionPropability": "0.2995",
    "latlng": [
      "46.4331",
      "14.99"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408254b18401dd30e55f",
    "infectionPropability": "0.7059",
    "latlng": [
      "48.4969",
      "12.2952"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140825bc230d6829fd456",
    "infectionPropability": "0.5157",
    "latlng": [
      "46.4948",
      "12.6171"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140824950d81aebc7bdcc",
    "infectionPropability": "0.8636",
    "latlng": [
      "47.877",
      "14.8308"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408273d77efa32eeb6eb",
    "infectionPropability": "0.9311",
    "latlng": [
      "47.4938",
      "11.7693"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140820c73013ff287a1e6",
    "infectionPropability": "0.6335",
    "latlng": [
      "48.0754",
      "11.5666"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140826d803882244c7155",
    "infectionPropability": "0.808",
    "latlng": [
      "47.9036",
      "13.8239"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a3c9070001d8147a",
    "infectionPropability": "0.6703",
    "latlng": [
      "46.6592",
      "13.8724"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822bd8f34335f6dc5f",
    "infectionPropability": "0.0977",
    "latlng": [
      "46.4082",
      "14.0151"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408251a79fd2faefa8c1",
    "infectionPropability": "0.8655",
    "latlng": [
      "47.7988",
      "14.339"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082074d367c9c524943",
    "infectionPropability": "0.8778",
    "latlng": [
      "48.8663",
      "14.1511"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822300fdb5b31bd9e4",
    "infectionPropability": "0.5963",
    "latlng": [
      "46.7204",
      "14.0303"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821689da73ec6d3ba3",
    "infectionPropability": "0.0138",
    "latlng": [
      "46.1051",
      "11.3774"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082f5e237dc831616b1",
    "infectionPropability": "0.9996",
    "latlng": [
      "47.6091",
      "11.2566"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140828251d77e6ab92a13",
    "infectionPropability": "0.6163",
    "latlng": [
      "46.8777",
      "12.5196"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408252b188a9873cc64a",
    "infectionPropability": "0.8066",
    "latlng": [
      "48.4626",
      "13.0547"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082842af40996ff11df",
    "infectionPropability": "0.2",
    "latlng": [
      "47.5096",
      "14.882"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140820c7e53605ca2a38b",
    "infectionPropability": "0.7217",
    "latlng": [
      "47.2711",
      "13.0633"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822ba06d0afe78b147",
    "infectionPropability": "0.2802",
    "latlng": [
      "48.8833",
      "13.3719"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822f042d69fee2e15a",
    "infectionPropability": "0.6341",
    "latlng": [
      "48.729",
      "11.3148"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082c4df034b7f8bd75e",
    "infectionPropability": "0.3972",
    "latlng": [
      "47.6327",
      "14.5706"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408263359e402d198186",
    "infectionPropability": "0.7547",
    "latlng": [
      "46.4504",
      "14.7628"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082f040aea11a01c508",
    "infectionPropability": "0.7544",
    "latlng": [
      "48.0328",
      "14.1222"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408287d6a8919f33180e",
    "infectionPropability": "0.818",
    "latlng": [
      "47.4811",
      "14.0947"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a01669a9a184323b",
    "infectionPropability": "0.0671",
    "latlng": [
      "46.9012",
      "14.3347"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140820a8eca00c6584904",
    "infectionPropability": "0.4253",
    "latlng": [
      "48.9181",
      "12.893"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408201327e38515fc9fd",
    "infectionPropability": "0.7818",
    "latlng": [
      "48.2913",
      "14.6698"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082eba6929b57d7600c",
    "infectionPropability": "0.9445",
    "latlng": [
      "47.5423",
      "11.9983"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140827d3b7dda52f8b306",
    "infectionPropability": "0.5528",
    "latlng": [
      "46.0675",
      "12.4774"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822d1f28b015a4aea1",
    "infectionPropability": "0.4776",
    "latlng": [
      "48.052",
      "14.625"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082d2022ba6225ce863",
    "infectionPropability": "0.1234",
    "latlng": [
      "46.2314",
      "14.5441"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821f976738d260dea8",
    "infectionPropability": "0.6343",
    "latlng": [
      "47.507",
      "11.2614"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140823a7db4a3d51d3239",
    "infectionPropability": "0.7196",
    "latlng": [
      "46.6803",
      "12.2853"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140824de6ed1d39ce7db5",
    "infectionPropability": "0.6697",
    "latlng": [
      "47.7731",
      "12.849"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a56a27d4cdc36cea",
    "infectionPropability": "0.9709",
    "latlng": [
      "48.3607",
      "11.0827"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a0cf0c1f36c0f81c",
    "infectionPropability": "0.2934",
    "latlng": [
      "46.3171",
      "11.1992"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140829cbc0a3a60816e48",
    "infectionPropability": "0.5911",
    "latlng": [
      "46.2286",
      "14.6068"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a00d884baeaa8309",
    "infectionPropability": "0.6236",
    "latlng": [
      "48.0205",
      "11.8989"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408244713ede0df37fe0",
    "infectionPropability": "0.2844",
    "latlng": [
      "46.644",
      "14.8389"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140826387bc58516d4bb7",
    "infectionPropability": "0.2426",
    "latlng": [
      "48.2695",
      "14.89"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082ccc6b099b1f7f76e",
    "infectionPropability": "0.2553",
    "latlng": [
      "48.1687",
      "14.1231"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140827bfe149cb22df611",
    "infectionPropability": "0.2025",
    "latlng": [
      "46.7694",
      "13.7325"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140824e56fc9b4b423e95",
    "infectionPropability": "0.1125",
    "latlng": [
      "46.9564",
      "13.9259"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140823ffb2119cb56fab6",
    "infectionPropability": "0.1416",
    "latlng": [
      "48.8891",
      "11.5176"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408206ea2ff002f4dd60",
    "infectionPropability": "0.9825",
    "latlng": [
      "46.5474",
      "11.5204"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082aaed86a9a642292e",
    "infectionPropability": "0.039",
    "latlng": [
      "46.9808",
      "14.9863"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140824073255e0afe326a",
    "infectionPropability": "0.0296",
    "latlng": [
      "47.016",
      "11.3756"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821ab81a7d05a7f4f8",
    "infectionPropability": "0.5326",
    "latlng": [
      "46.8115",
      "14.2188"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082f485c5fcf4e5be10",
    "infectionPropability": "0.0575",
    "latlng": [
      "46.2684",
      "14.9156"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082c691371621dfcddc",
    "infectionPropability": "0.8409",
    "latlng": [
      "47.8315",
      "12.2977"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082af5c14e937442a71",
    "infectionPropability": "0.1823",
    "latlng": [
      "48.3709",
      "11.2028"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821d11364605c3211e",
    "infectionPropability": "0.9582",
    "latlng": [
      "48.2031",
      "13.0292"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821f09aa145848f5e2",
    "infectionPropability": "0.7476",
    "latlng": [
      "46.6662",
      "12.7603"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082adbe6ae43e409bca",
    "infectionPropability": "0.3588",
    "latlng": [
      "48.6412",
      "13.037"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140825c724d48f30960e9",
    "infectionPropability": "0.8859",
    "latlng": [
      "48.1037",
      "13.5865"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082d6851d8db0b46895",
    "infectionPropability": "0.8517",
    "latlng": [
      "46.702",
      "12.0825"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140820ae53a7308e646da",
    "infectionPropability": "0.0414",
    "latlng": [
      "47.5664",
      "12.7244"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082f8d2142f67f15a4a",
    "infectionPropability": "0.2156",
    "latlng": [
      "46.1849",
      "11.8789"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408220d8f9af5f860567",
    "infectionPropability": "0.3062",
    "latlng": [
      "46.8853",
      "14.8452"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082118754fadb2e5da0",
    "infectionPropability": "0.4256",
    "latlng": [
      "47.1784",
      "14.8805"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821f7bdcd6e19e22e1",
    "infectionPropability": "0.8115",
    "latlng": [
      "46.2154",
      "13.0766"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082c52478a4db10fcd8",
    "infectionPropability": "0.4535",
    "latlng": [
      "46.4232",
      "13.7542"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082637742e00dd549ae",
    "infectionPropability": "0.3283",
    "latlng": [
      "48.1076",
      "14.3418"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140823123da9b376a53db",
    "infectionPropability": "0.663",
    "latlng": [
      "47.7924",
      "13.9882"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082fd4b0c80f763a163",
    "infectionPropability": "0.2385",
    "latlng": [
      "46.5684",
      "13.2045"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140824fb3d5da19f219ed",
    "infectionPropability": "0.7205",
    "latlng": [
      "48.5202",
      "11.1213"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140825f9f85acaa85eec4",
    "infectionPropability": "0.5739",
    "latlng": [
      "47.5368",
      "13.9864"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140827f92b4d0d8362621",
    "infectionPropability": "0.0507",
    "latlng": [
      "47.7537",
      "12.6652"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140829007be13faf96f27",
    "infectionPropability": "0.6217",
    "latlng": [
      "48.5645",
      "11.5606"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082bc0f61dfbfae8b3a",
    "infectionPropability": "0.2178",
    "latlng": [
      "48.1426",
      "11.2368"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082933ca9086ae9f249",
    "infectionPropability": "0.7016",
    "latlng": [
      "48.8206",
      "14.7659"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408219d9adc0b134a605",
    "infectionPropability": "0.7007",
    "latlng": [
      "46.316",
      "14.9732"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082edf923f3c776a2d2",
    "infectionPropability": "0.7101",
    "latlng": [
      "47.8492",
      "11.8568"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082624d82801109c473",
    "infectionPropability": "0.1057",
    "latlng": [
      "46.2972",
      "11.201"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140826fb0fddca1fd9592",
    "infectionPropability": "0.6369",
    "latlng": [
      "47.8145",
      "14.0709"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408221bff7f2b823998d",
    "infectionPropability": "0.3913",
    "latlng": [
      "46.848",
      "14.5931"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a3d2c28569cd34fb",
    "infectionPropability": "0.9466",
    "latlng": [
      "48.8357",
      "12.9131"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140829e3ec874450beecb",
    "infectionPropability": "0.8986",
    "latlng": [
      "47.5868",
      "11.5418"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082822d702d07237e2b",
    "infectionPropability": "0.8721",
    "latlng": [
      "46.3838",
      "11.6024"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082ab22cd5cea48ef9c",
    "infectionPropability": "0.1134",
    "latlng": [
      "46.3127",
      "11.4002"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408202f877b702fa2012",
    "infectionPropability": "0.3635",
    "latlng": [
      "46.4246",
      "14.5838"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082f761481dbb8ef732",
    "infectionPropability": "0.9911",
    "latlng": [
      "48.796",
      "13.7707"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082286c50796d45130f",
    "infectionPropability": "0.5805",
    "latlng": [
      "47.0962",
      "11.7155"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082752173fa6dde3d15",
    "infectionPropability": "0.7166",
    "latlng": [
      "48.0286",
      "12.1453"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408210ef0d62ba2981b2",
    "infectionPropability": "0.5059",
    "latlng": [
      "47.6332",
      "14.5777"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082fd0386bce628e632",
    "infectionPropability": "0.9547",
    "latlng": [
      "46.78",
      "14.7701"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140827095d579f2078acf",
    "infectionPropability": "0.2664",
    "latlng": [
      "47.7208",
      "11.8275"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e81408270cbd24d2b3f8696",
    "infectionPropability": "0.882",
    "latlng": [
      "46.8726",
      "11.4799"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082d8130c128dd1228a",
    "infectionPropability": "0.3665",
    "latlng": [
      "47.6972",
      "14.4673"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082ccc7a89e84251993",
    "infectionPropability": "0.9087",
    "latlng": [
      "47.9238",
      "14.0578"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822478c82bd1ca7b81",
    "infectionPropability": "0.6483",
    "latlng": [
      "48.68",
      "13.0084"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140820feef7898b59b447",
    "infectionPropability": "0.4899",
    "latlng": [
      "46.9",
      "12.0638"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082a67b6be054fd109f",
    "infectionPropability": "0.3303",
    "latlng": [
      "46.5528",
      "12.1423"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082231635b09e3efddb",
    "infectionPropability": "0.0954",
    "latlng": [
      "46.2943",
      "11.0139"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082ea270dffedb3222a",
    "infectionPropability": "0.3261",
    "latlng": [
      "47.9851",
      "13.6679"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082efdf2c0b80f87939",
    "infectionPropability": "0.0713",
    "latlng": [
      "47.8083",
      "13.6886"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082d964f3c3ffadfd58",
    "infectionPropability": "0.2567",
    "latlng": [
      "48.4136",
      "13.8551"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e814082cc6afd1f70679af0",
    "infectionPropability": "0.7197",
    "latlng": [
      "46.909",
      "13.0332"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140821ce25fa79ff19d91",
    "infectionPropability": "0.1268",
    "latlng": [
      "47.315",
      "13.68"
    ],
    "country": "Mordor"
  },
  {
    "id": "5e8140822277382ef74b230d",
    "infectionPropability": "0.0986",
    "latlng": [
      "47.3686",
      "14.9483"
    ],
    "country": "Mordor"
  }
];
