import { css } from 'styled-components';

export const fullViewport = css`
  width: 100vw;
  height: 100vh;
`;

export const flexCentering = css`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
